import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../components/CheckoutForm";
import { useAppState } from "../providers/state";
import { symbolFromCurrency } from "../utils/currency.helper";

export default function Payment(): JSX.Element {
  const { state } = useAppState();

  const navigate = useNavigate();
  const queryParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  const queryCurrency = queryParams.get("currency");
  const currency: string = queryCurrency ? queryCurrency : "gbp";

  const { account_id } = Object.fromEntries(queryParams.entries());
  if (!account_id) {
    console.error("account_id is missing");
  }
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string;

  useEffect(() => {
    if (state === null) {
      navigate("/?account_id=" + account_id + "&currency=" + currency);
    }
  });

  const amount = !!state ? state.amount : 1;

  const [stripePromise] = useState(
    loadStripe(publishableKey, {
      stripeAccount: account_id,
    })
  );

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={{
        initial: {
          opacity: 0,
          x: "-100vw",
        },
        in: {
          opacity: 1,
          x: 0,
        },
        out: {
          opacity: 0,
          x: "100vw",
        },
      }}
      transition={{
        type: "tween",
        ease: "anticipate",
        duration: 0.5,
      }}
    >
      <Grid container spacing={2} padding={1}>
        <Grid item xs={12}>
          <Card
            sx={{
              borderRadius: 2,
              bgcolor: "#ffffff",
              boxShadow: "20px 20px 60px #c8c8c8, -20px -20px 60px #ffffff",
            }}
          >
            <CardContent>
              <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Donation</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid container item xs={12} sx={{ alignItems: "center" }}>
                  <Grid item xs={6} alignItems={"left"}>
                    <Typography variant="body1">Amount:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ alignItems: "right" }}>
                    <Typography variant="body1" sx={{ float: "right" }}>
                      {`${symbolFromCurrency(currency)} ${amount}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              borderRadius: 2,
              bgcolor: "#ffffff",
              boxShadow: "20px 20px 60px #c8c8c8, -20px -20px 60px #ffffff",
            }}
          >
            <CardContent>
              <Elements
                stripe={stripePromise}
                options={{
                  appearance: { theme: "stripe" },
                  mode: "payment",
                  amount: parseInt(amount) * 100,
                  currency: currency,
                }}
              >
                <CheckoutForm
                  account_id={account_id}
                  currency={currency}
                  amount={amount}
                />
              </Elements>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </motion.div>
  );
}
