import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import giftAidLogo from "../assets/Gift_Aid_UK_Logo.svg";
import stripeGuarantee from "../assets/Powered-by-Stripe-Outline-Logo.svg";
import {
  confirmPayment,
  sendPaymentIntentToServer,
} from "../utils/payment.helper";
import axios from "axios";

export default function CheckoutForm({
  account_id,
  amount,
  currency,
}: {
  account_id: string;
  amount: string;
  currency: string;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState("");

  const [emailAdress, setEmailAdress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState(false);

  const [giftAid, setGiftAid] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

  const clearPaymentProcessing = () => {
    setIsPaymentProcessing(false);
    bubbleMessageToiframe("submitted");
  };

  const bubbleMessageToiframe = (next_utterance: string) => {
    window.parent.postMessage(
      {
        type: "chat2impact.donation.processed",
        next_utterance: next_utterance,
      },
      "*"
    );
  };

  const handleSubmit = async () => {
    //clear error message for next submit
    setEmailAddressError(false);

    if (errorMessage !== "") {
      setErrorMessage("");
    }

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setEmailAddressError(emailAdress.length === 0);
    const { error: submitError } = await elements.submit();

    if (emailAdress.length === 0 || submitError) {
      if (submitError) {
        console.error("Error:", submitError);
        setErrorMessage(submitError.message ?? "An error occurred");
      }
      return;
    }

    setIsPaymentProcessing(true);

    const paymentIntent = await sendPaymentIntentToServer({
      amount,
      currency,
      account_id,
      email: emailAdress,
      giftAid,
    });

    const fulfilledPayment = await confirmPayment(
      stripe,
      elements,
      paymentIntent.client_secret,
      `${window.location.origin}/success?account_id=${account_id}`,
      setErrorMessage
    );

    if (fulfilledPayment) {
      const analyticAPIURL = process.env.REACT_APP_ANALYTICS_API_URL as string;

      axios
        .post(analyticAPIURL + "/impacts/donation", {
          account_id: account_id,
          amount: amount,
          currency: currency,
        })
        .then((response) => {
          console.log("Analytics tracked", response);

          clearPaymentProcessing();
        })
        .catch((error) => {
          console.error(error);

          clearPaymentProcessing();
        });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} marginTop={2} padding={"0px 16px"}>
        <Typography variant="h5">Payment Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{ border: 0 }}
          component="img"
          alt="Stripe Trust Badge"
          src={stripeGuarantee}
          width={"100%"}
        ></Box>
      </Grid>
      <Grid item xs={12}>
        <PaymentElement
          options={{
            business: { name: "Chat2Impact" },
            layout: {
              type: "accordion",
              defaultCollapsed: false,
              radios: true,
              spacedAccordionItems: false,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Typography variant="h5">Billing Details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "0.75rem" }}>
        <Box
          sx={{
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: "#30313d",
            lineHeight: "1.15",
            fontSize: "0.93rem",
            mb: 1,
          }}
        >
          E-mail Address
        </Box>
        <input
          placeholder="E-mail Address"
          value={emailAdress}
          style={{
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            color: "#30313d",
            lineHeight: "1.15",
            fontSize: "0.93rem",
            display: "block",
            width: "100%",
            padding: "0.75rem",
            backgroundColor: "#fff",
            borderRadius: "5px",
            transition:
              "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
            border: emailAddressError
              ? "1px solid #df1b41"
              : "1px solid #e6e6e6",
            boxSizing: "border-box",
            boxShadow:
              "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)",
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmailAdress(event.target.value || "");
          }}
        />
        {emailAddressError && (
          <Box
            sx={{
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
              color: "#df1b41",
              lineHeight: "1.15",
              fontSize: "0.93rem",
            }}
          >
            Please enter a valid email address
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <AddressElement options={{ mode: "billing" }} />
      </Grid>

      {currency === "gbp" && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{ border: 0 }}
                    component="img"
                    alt="Stripe Trust Badge"
                    src={giftAidLogo}
                    width={"100%"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Checkbox
                    defaultChecked={false}
                    onClick={(value) => {
                      setGiftAid(!giftAid);
                    }}
                  ></Checkbox>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">
                    I want to claim Gift Aid on my donation made today. I am a
                    UK taxpayer and I understand that if I pay less Income Tax
                    and/or Capital Gains Tax than the amount of Gift Aid claimed
                    on all my donations in that tax year it is my responsibility
                    to pay any difference
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          {errorMessage && <div>{errorMessage}</div>}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sx={{
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!stripe || !elements || isPaymentProcessing}
        >
          Pay now
        </Button>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sx={{
          justifyContent: "center",
          display: isPaymentProcessing ? "flex" : "none",
        }}
      >
        <CircularProgress></CircularProgress>
      </Grid>
    </Grid>
  );
}
